const formatPrice = (cents, indexLang) => {
    let withoutDollarSign = `${(Math.round(cents) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    if (indexLang) {
        withoutDollarSign += '$'; 
    } else {
        withoutDollarSign = '$' + withoutDollarSign; 
    }
    return withoutDollarSign;
}

export default formatPrice
