import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { Field, reduxForm, submit } from 'redux-form';
import { fetchUpdateAddress } from '../../actions/orders';
import { ADRESSMODIFIED, MODIFYADDRESS, MODIFYADDRESS2, ADRESSES, NEWORDERSHIPPING, NEWORDERTOTAL, ORDERPRICECHANGED } from '../../constant/stringTraduction';
import SuccessableButton from '../buttons/SuccessableButton';
import validateAddress from '../../utils/validateAddress';
import AddressFields from '../addresses/AddressFields';
import formatPrice from '../../utils/formatPrice';

const OrderModifyAddress = ({
    order,
    handleSubmit,
    dispatch,
    indexLang,
    isFetching,
    pristine,
    invalid,
    resetError,
    submitSucceededAddress,
    submittingAddress,
    errorAddress,
    newAddress,
    handleAddressChange,
    addresses,
    renderSelectFieldAddress,
    selectedAddressId,
    orderPriceChanged,
}) => {

    const handleAddressFormSubmit = (values) => {
        dispatch(fetchUpdateAddress({ values, order, indexLang }));
    }

    const submitNewAddressForm = () => {
        submit('NewAddressForm');
    }

    return (
        <Container className='white-background'>
            <div>
                <Row>
                    <Col lg='12' style={{ marginTop: '50px' }}><h4>{MODIFYADDRESS[indexLang]}</h4></Col>
                </Row>
                <Row>
                    <Col md='6' style={{ marginBottom: '20px' }}>
                        <div>{order.address.street}</div>
                        <Col lg='12'>{order.address.city}, {order.address.state}</Col>
                        <Col lg='12'>{order.address.zip}</Col>
                        <Col lg='12'>{order.address.phone}</Col>
                    </Col>
                    <Col md='6'>
                        {orderPriceChanged &&
                            <div>
                                <Col lg='12'><b>{ORDERPRICECHANGED[indexLang]}</b></Col>
                                <Col lg='12'>{NEWORDERSHIPPING[indexLang]} : {formatPrice(order.shipping, indexLang)}</Col>
                                <Col lg='12'>{NEWORDERTOTAL[indexLang]} : {formatPrice(order.total, indexLang)}</Col>
                            </div>
                        }
                    </Col>
                </Row>
                <Row>
                    <div style={{ width: '100%' }}>
                        <form onSubmit={handleSubmit(handleAddressFormSubmit)}>
                            <div className="field-container">
                                <Field
                                    color="black"
                                    name="fullAddress"
                                    indexLang={indexLang}
                                    component={renderSelectFieldAddress}
                                    label={ADRESSES[indexLang]}
                                    fullWidth={true}
                                    className="field"
                                    options={addresses}
                                    handleAddressChange={handleAddressChange}
                                />
                                {newAddress &&
                                    <div style={{ border: '1px solid #ccc', marginTop: 5, padding: '5px 10px' }}>
                                        <AddressFields
                                            autoFocus={false}
                                            indexLang={indexLang}
                                        />
                                    </div>
                                }
                            </div>
                            <Col lg='12' style={{ width: '100%', textAlign: 'center', marginTop: '15px' }}>
                                {isFetching ?
                                    <CircularProgress size={36} style={{ verticalAlign: 'middle', color: '#000000' }} />
                                    :
                                    <SuccessableButton
                                        disabled={pristine || invalid || !selectedAddressId}
                                        error={errorAddress}
                                        label={MODIFYADDRESS2[indexLang]}
                                        reset={resetError}
                                        submitSucceeded={submitSucceededAddress}
                                        submitting={submittingAddress}
                                        successLabel={ADRESSMODIFIED[indexLang]}
                                        onMouseUp={submitNewAddressForm}
                                    />
                                }
                            </Col>
                        </form>
                    </div>
                </Row>
            </div>
        </Container>
    )
}

export default reduxForm({
    form: 'NewAddressForm',
    validate: validateAddress,
    onSubmit: submit,
    destroyOnUnmount: false,
})(withRouter(OrderModifyAddress))
