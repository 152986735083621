import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { signout } from '../../actions/user';
import { BONJOUR, MYACCOUNT } from '../../constant/stringTraduction';
import './header.css';
import UserButtons from './UserButtons';
import { getClosed } from '../../utils/specialEvents';

class AppBarUser extends Component {
    state = {
        anchorEl: null,
        horizontal: 'left',
        navClass: null,
        openMenu: false,
        width: 0,
    }
    handleOpenMenu = (e) => {
        e.preventDefault()
        this.setState({ openMenu: true, anchorEl: e.currentTarget })
    }
    handleCloseMenu = () => this.setState({ openMenu: false, anchorEl: null })
    handleSignout = () => {
        const { dispatch, history } = this.props
        this.handleCloseMenu()
        return dispatch(signout(history))
    }
    componentDidMount() {
        if (getClosed()) {
            return;
        }
        const { cartQty } = this.props
        const width = this.userNav.clientWidth
        const totalWidth = width / .2
        let navClass
        switch (true) {
            case totalWidth < 375:
                navClass = 'largerThanIphone375'
                break
            case totalWidth < 667:
                navClass = 'largerThanIphone667'
                break
            case totalWidth < 768:
                navClass = 'largerThanIpad768'
                break
            case totalWidth < 1024:
                navClass = 'largerThanIpad1024'
                break
            case totalWidth < 1366:
                navClass = 'largerThanIpad1366'
                break
            default:
                navClass = 'largerThan1920'
        }
        const horizontal = cartQty > 0 ? 'left' : 'right'
        this.setState({ horizontal, navClass, width })
    }

    render() {
        const { horizontal, navClass } = this.state
        const {
            color,
            dispatch,
            firstName,
            history,
            indexLang,
        } = this.props
        return (
            (!getClosed() &&
            <div
                ref={(userNav) => {
                    this.userNav = userNav
                }}
                style={{ display: 'flex', alignItems: 'center' }}
                className={navClass}
            >
                <Button
                    onClick={this.handleOpenMenu}
                    label={{ padding: 0 }}
                    style={{ color, margin: '0 0 0 0' }}
                >{firstName ? `${BONJOUR[indexLang]}, ${firstName}` : MYACCOUNT[indexLang]}
                </Button>
                <Popover
                    open={this.state.openMenu}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{ horizontal, vertical: 'bottom' }}
                    onClose={this.handleCloseMenu}
                    style={{ color: '#000000' }}
                >
                    <UserButtons
                        history={history}
                        dispatch={dispatch}
                        firstName={firstName}
                        onSelect={this.handleCloseMenu}
                        indexLang={indexLang}
                    />
                </Popover>
            </div>
            )
        )
    }
}

export default withRouter(AppBarUser)
