import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import { ReactComponent as ChoosePack } from '../../assets/choose_pack.svg';
import { ReactComponent as Party } from '../../assets/party.svg';
import { ReactComponent as ReceivePack } from '../../assets/receive_pack.svg';
import tag_party from '../../assets/tag_party.png';
import { ReactComponent as PictoCan } from '../../assets/pictos/canada.svg'
import { ReactComponent as PictoQuebec } from '../../assets/pictos/qc.svg'
import picto_saisons from '../../assets/pictos/seasonal.png'
import { ReactComponent as PictoJouetLaisse } from '../../assets/pictos/products.svg'
import { ReactComponent as PictoMain } from '../../assets/pictos/human_pet.svg'
import {
    ABONNEMENTDEBUTEA, ABONNEMENTDEBUTEA2, BOXDROP, CATTYPING, GOSUBSCRIBE, CHOOSEPACK, COMMENTCAFONCTIONNE1,
    COMMENTCAFONCTIONNE2, COMMENTCAFONCTIONNE3, ETE, FETEJAMAISTERMINEE, HIVER, PARTYBOX, PARTYSTARTED, 
    PERSONNALISEPLAN, PERSONNALISEPLAN2, RECEIVEPACK, RECEVEZCHAQUESAISON, STARTTHEPARTY, YELLOWCIRCLEWITH,
    TRYOUT, PRODUCTVARIETY, CANADIANCOMPAGNY, FLEXIBLESUBSCRIPTION, SEASONALBOX, TIMESPERYEAR, PACKEXAMPLE,
    TRYOUTSHORT,
} from '../../constant/stringTraduction';
import homeContainer from '../../containers/home/homeContainer';
import './home.css';
import { getNextPackDate } from '../../utils/dates';
import SignUpModal from './SignUpModal'
import AsSeenOn from '../asSeenOn/AsSeenOn';
import Unboxings from './Unboxings';
import Compteur from './Compteur';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';
import Partners from '../partners/Partners';
import WhatsInside from './WhatsInside'
import { TRIAL_URL, GPWINTER_URL, REGULAR_URL } from '../../constant/otherConst';
import { getNoMoreOrders } from '../../utils/specialEvents';

class Home extends Component {
    constructor() {
        super();
        this.state = {
            indexSaison: 0,
            secondsTilNextBox: 0,
            secondsTilTrial: 5,
            modal: false,
            videoRCOpened: false,
            videoSBOpened: false,
            referenceAlertVisible: false,
        }
        this.toggle = this.toggle.bind(this);
        this.trialBox = this.trialBox.bind(this);
        this.subscribe = this.subscribe.bind(this);
        this.openVideo = this.openVideo.bind(this);
        this.closeVideo = this.closeVideo.bind(this);
        this.onDismissReferenceAlert = this.onDismissReferenceAlert.bind(this);
    }

    componentDidMount() {
        this.setDate();
        if (getNoMoreOrders()) {
            return;
        }
        const self = this;
        const { trial } = this.props;
        this.idInterval = window.setInterval(function () {
            if (self.state === 0) {
                self.setDate();
            }
            self.setState({ secondsTilNextBox: self.state.secondsTilNextBox - 1 })
        }, 1000);
        this.trialInterval = setInterval(() => {
            if (trial === false) {
                this.setState({ modal: true });
            }
            window.clearInterval(this.trialInterval);

        }, 7500);
        this.referenceInterval = setInterval(() => {
            this.setState({ referenceAlertVisible: true })
            window.clearInterval(this.referenceInterval);
        }, 2000);
        this.checkIfCertainURL();
    }

    componentWillUnmount() {
        if (getNoMoreOrders()) {
            return;
        }
        window.clearInterval(this.idInterval);
        window.clearInterval(this.trialInterval);
        window.clearInterval(this.referenceInterval);
    }

    setDate() {
        const { indexSaison, secondsTilNextBox } = getNextPackDate();
        this.setState({
            indexSaison: indexSaison,
            secondsTilNextBox: secondsTilNextBox,
        });
    }

    toggle() {
        const { dispatch } = this.props;
        this.setState({ modal: !this.state.modal });
        return dispatch({ type: 'SET_TRIAL', payload: false });
    }

    subscribe() {
        const { dispatch, history } = this.props;
        dispatch({ type: 'SET_TRIAL', payload: false });
        history.push('/box');
    }

    trialBox() {
        const { history } = this.props;
        this.enableTrialMode();
        history.push('/box');
    }

    enableTrialMode() {
        const { dispatch } = this.props;
        dispatch({ type: 'SET_TRIAL', payload: true });
    }

    checkIfCertainURL() {
        const { history, location, dispatch } = this.props;
        const possibleUrlParts = [TRIAL_URL, GPWINTER_URL];
        const context = this;
        possibleUrlParts.forEach(function(urlPart) {
            if (location.pathname.toLowerCase().includes(urlPart)) {
                if (urlPart === TRIAL_URL) {
                    context.enableTrialMode();
                }
                if (urlPart === TRIAL_URL || urlPart === GPWINTER_URL) {
                    dispatch({ type: 'SET_CUSTOM_SLIDER', payload: urlPart });
                    history.push('/');
                }
            }
        });
    }

    scrollToVideo() {
        let x = document.getElementById('showcase_video')
        smoothScrollIntoView(x, { scrollMode: 'if-needed', block: 'center', behavior: 'smooth' });
    }

    openVideo(index) {
        if (index === 0) {
            this.setState({
                videoRCOpened: true,
                videoSBOpened: false,
            });
        } else if (index === 1) {
            this.setState({
                videoRCOpened: false,
                videoSBOpened: true,
            });
        }
    }

    closeVideo() {
        this.setState({
            videoRCOpened: false,
            videoSBOpened: false,
        });

    }

    onDismissReferenceAlert() {
        this.setState({ referenceAlertVisible: false });
    }
    render() {
        const {
            indexLang,
            orders,
            user,
            trial,
            customSlider,
        } = this.props;

        const secondes = this.state.secondsTilNextBox % 60;
        const minutes = Math.floor(this.state.secondsTilNextBox / 60) % 60;
        const heures = Math.floor(this.state.secondsTilNextBox / 3600) % 24;
        const jours = Math.floor(this.state.secondsTilNextBox / 86400);

        // 1: trial, 2: season, 3: indexLang
        let headerImageCSS = [];
        headerImageCSS[REGULAR_URL] = [['banner-home-summer-english', 'banner-home-summer-french'], ['banner-home-summer-english', 'banner-home-summer-french']];

        headerImageCSS[TRIAL_URL] = [['banner-home-winter-discovery-english', 'banner-home-winter-discovery-french'], ['banner-home-winter-discovery-english', 'banner-home-winter-discovery-french']];
        headerImageCSS[GPWINTER_URL] = [['banner-home-winter-25gpwinter-english', 'banner-home-winter-25gpwinter-french'], ['banner-home-winter-25gpwinter-english', 'banner-home-winter-25gpwinter-french']];
        const trial_button_classes = ['homepage-trial-button homepage-trial-button-winter', 'homepage-trial-button homepage-trial-button-summer'];
        const saison = [HIVER[indexLang].toUpperCase(), ETE[indexLang].toUpperCase()][this.state.indexSaison];
        return (
            <div className="white-background" style={{ textAlign: 'center', marginTop: '64px', maxWidth:'100%' }}>
                <div className={headerImageCSS[customSlider][this.state.indexSaison][indexLang]}>
                    {!getNoMoreOrders() &&
                    <div className="button-tag-party">
                        <Link to="/box">
                            <Button size="lg" className="yellow-button start-button" style={{ fontFamily: 'Gotham-Black', border: 'none' }}>
                                {trial ? TRYOUTSHORT[indexLang] : GOSUBSCRIBE[indexLang]}
                            </Button>
                        </Link>
                        <Button size="lg" style={{ height: 46 }} onClick={this.scrollToVideo}><span className="fa fa-video-camera"></span></Button>
                    </div>
                    }
                </div>
                {!getNoMoreOrders() &&
                <Container>
                    <div className="div-img">
                        <div className="div-img-2">
                            <img className="tag-party" src={tag_party} alt={YELLOWCIRCLEWITH[indexLang]} style={{ display: 'none' }} />
                            <h2 style={{ fontWeight: '800', color: 'white', maxWidth: '187px', minWidth: '160px', maxHeight: '115px', textAlign: 'left', marginLeft: -10, display: 'none' }}>{FETEJAMAISTERMINEE[indexLang]}</h2>
                            <Link to="/box" className="button-tag-party" style={{ display: 'none' }}><Button size="lg" className="yellow-button" style={{ fontSize: 24, padding: '10px 30px', fontFamily: 'Gotham-Black', border: 'none' }}>{trial ? TRYOUTSHORT : GOSUBSCRIBE[indexLang]}</Button></Link>
                        </div>
                    </div>
                </Container>
                }
                <Row style={{ padding:30 }}>
                    <Col sm='3'>
                        <Row>
                            <Col lg='5' >
                                <PictoJouetLaisse className="img-picto" alt="Picto jouet_laisse" />
                            </Col>
                            <Col lg='7' style={{ margin:'auto' }}>
                                <h3 className="picto-description">{PRODUCTVARIETY[indexLang]}</h3>
                            </Col>  
                        </Row>
                    </Col>
                    <Col sm='3'>
                        <Row>
                            <Col lg='5' >
                                {indexLang === 1 ? <PictoQuebec className="img-picto" alt="Picto pays"/> : <PictoCan className="img-picto" alt="Picto pays"/>}
                            </Col>
                            <Col lg='7' style={{ margin:'auto' }}>
                                <h3 className="picto-description">{CANADIANCOMPAGNY[indexLang]}</h3>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm='3'>
                        <Row>
                            <Col lg='5' >
                                <PictoMain className="img-picto" alt="Picto main"/>
                            </Col>
                            <Col lg='7' style={{ margin:'auto' }}>
                                <h3 className="picto-description">{FLEXIBLESUBSCRIPTION[indexLang]}</h3>
                            </Col>  
                        </Row>
                    </Col>
                    <Col sm='3'>
                        <Row>
                            <Col lg='5' >
                                <img className="img-picto" src={picto_saisons} alt="Picto saisons"></img>
                            </Col>
                            <Col lg='7' style={{ margin:'auto' }}>
                                <h3 className="picto-description">{SEASONALBOX[indexLang]}</h3>
                                <h3 className="picto-description">{TIMESPERYEAR[indexLang]}</h3>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {!getNoMoreOrders() &&
                <div>
                    <Button
                        className={trial_button_classes[this.state.indexSaison]}
                        size="lg"
                        style={{
                            padding: '10px 30px',
                            marginBottom: '30px',
                            fontSize: 18,
                            fontFamily: 'Gotham-Black',
                            border: 'none',
                        }}
                        onClick={this.trialBox}>
                        {TRYOUT[indexLang]}
                    </Button>
                </div>
                }
                <div style={{ backgroundColor:'#FFF100' }}>
                    <Container style={{ padding:10 }}>
                        <h2 style={{ marginBottom: 20 }}>{COMMENTCAFONCTIONNE1[indexLang]}<span style={{ fontWeight: '800', fontSize: '38px' }}>{COMMENTCAFONCTIONNE2[indexLang]}</span>{COMMENTCAFONCTIONNE3[indexLang]}</h2>
                        <Row>
                            <Col sm='4'>
                                <div className="first-step home-gp-steps">1.</div>
                            </Col>
                            <Col sm='4'>
                                <div className="second-step home-gp-steps">2.</div>
                            </Col>
                            <Col sm='4'>
                                <div className="third-step home-gp-steps">3.</div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div>
                    <Container>
                        <div>
                            <Row>
                                <Col sm="4">
                                    <ChoosePack className='picto-home' alt={CATTYPING[indexLang]} />
                                    <h2 className='explication-title'>{CHOOSEPACK[indexLang]}</h2>
                                    <h3 className='explication-text'>{PERSONNALISEPLAN[indexLang]}<br />{PERSONNALISEPLAN2[indexLang]}</h3>
                                    <br />
                                </Col>
                                <Col sm="4">
                                    <ReceivePack className='picto-home' alt={BOXDROP[indexLang]} />
                                    <h2 className='explication-title'>{RECEIVEPACK[indexLang]}</h2>
                                    <h3 className='explication-text'>{ABONNEMENTDEBUTEA[indexLang]} <Link to='/learn-more#subscription_calendar' className='link-white-back'>{ABONNEMENTDEBUTEA2[indexLang]}</Link></h3>
                                    <br />
                                </Col>
                                <Col sm="4">
                                    <Party className='picto-home' alt={PARTYBOX[indexLang]} />
                                    <h2 className='explication-title'>{PARTYSTARTED[indexLang]}</h2>
                                    <h3 className='explication-text'>{RECEVEZCHAQUESAISON[indexLang]}</h3>
                                    <br />
                                </Col>
                            </Row>
                            <Row style={{ paddingBottom: '3rem' }}>
                                {!getNoMoreOrders() &&
                                    <Col md="6">
                                        <Link to="/box"><Button className="yellow-button home-button-left" style={{ border: 'none' }} size="lg">{STARTTHEPARTY[indexLang]}</Button></Link>
                                    </Col>
                                }
                                <Col md={!getNoMoreOrders() ? '6' : '12'}>
                                    <Link to="/pack-examples"><Button className={!getNoMoreOrders() ? 'black-button home-button-right' : 'black-button'} style={{ border: 'none', fontSize: '17px' }} size="lg">{PACKEXAMPLE[indexLang]}</Button></Link>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                <div style={{ backgroundColor:'black' }}>
                    {!getNoMoreOrders() ?
                        <Compteur
                            saison={saison}
                            jours={jours}
                            heures={heures}
                            minutes={minutes}
                            secondes={secondes}
                            indexLang={indexLang}
                        /> :
                        <div>

                        </div>
                    }
                </div>
                <div>
                    <WhatsInside
                        indexLang={indexLang}
                    />
                    <Unboxings />
                    <AsSeenOn
                        indexLang={indexLang}
                        openVideo={this.openVideo}
                        videoRCOpened={this.state.videoRCOpened}
                        videoSBOpened={this.state.videoSBOpened}
                        closeViceo={this.closeVideo}
                    />
                    <Partners
                        indexLang={indexLang}
                    />
                </div>
                <SignUpModal
                    indexLang={indexLang}
                    orders={orders}
                    user={user}
                    modal={this.state.modal}
                    toggle={this.toggle}
                    subscribe={this.subscribe}
                    trialBox={this.trialBox}
                    isAdding={false}
                />
            </div>
        );
    }
}

export default homeContainer(Home)
