import React, { Component } from 'react';
import catmedium from '../../../assets/selection/cat_medium_150.png';
import { TEXTECHATMEDIUM } from '../../../constant/stringTraduction';
export default class CatMediumPicto extends Component {
    render () {
        return (
            <div>
                <img src={catmedium} alt="chat medium 10+lbs"></img>
                <div><b>{TEXTECHATMEDIUM[this.props.indexLang]}</b></div>
                <div>10+ lbs</div>
            </div>
        );
    }
}
