import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import React, { Component } from 'react';
import { BONJOUR } from '../../constant/stringTraduction';
import headerContainer from '../../containers/header/headerContainer';
import AppBarNavigation from './AppBarNavigation';
import DrawerNavigation from './DrawerNavigation';
import './header.css';
import HeaderBrand from './HeaderBrand';
import { toggleDrawer } from '../../actions/drawer';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';

class Header extends Component {
  handleDrawerToggle = () => {
      this.props.dispatch(toggleDrawer())
  }

  render () {
      const {
          cart,
          dispatch,
          drawer,
          firstName,
          isAdmin,
          isOwner,
          indexLang,
      } = this.props
      return (
          <header>
              <AppBar
                  title="GoodyPack"
                  style={{ background: '#ffffff' }}
              >
                  <nav>
                      <div className="appbar">
                          <HeaderBrand
                              dispatch={dispatch}
                              className="appbar-brand"
                              fontWeight={'600'}
                              name="GoodyPack"
                          />
                          <AppBarNavigation
                              cart={cart}
                              dispatch={dispatch}
                              firstName={firstName}
                              indexLang={indexLang}
                          />
                      </div>
                  </nav>
              </AppBar>
              <Drawer
                  variant="temporary"
                  docked="false"
                  onClose={this.handleDrawerToggle}
                  open={drawer.open}
                  style={{ color: '#000000' }}
              >

                  <IconButton
                      style={{ marginTop: '8px', marginLeft: 'auto', marginRight: 'auto' }}
                      aria-label="Open drawer"
                      onClick={this.handleDrawerToggle}
                  >
                      <MenuIcon />
                  </IconButton>

                  {firstName && <div className="drawer-user">{BONJOUR[indexLang]}, {firstName}</div>}
                  <DrawerNavigation
                      cart={cart}
                      dispatch={dispatch}
                      firstName={firstName}
                      isAdmin={isAdmin}
                      isOwner={isOwner}
                      indexLang={indexLang}
                  />
              </Drawer>
          </header>
      )
  }
}

export default headerContainer(Header)
