const coupons = (state = {
    coupons: [],
}, action) => {
    switch (action.type) {
        case 'SET_COUPONS':
            return {
                ...state,
                coupons: action.payload,
            }
        case 'ADD_COUPONS_TO_ALL':
            return {
                ...state,
                coupons: [...state.coupons, action.payload],
            }
        case 'REMOVE_COUPON_FROM_ALL':
            return {
                ...state,
                coupons: state.coupons.filter((item) => item._id !== action.payload._id),
            }
        default:
            return state
    }
}

export default coupons
