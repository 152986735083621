import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from '@material-ui/core';

class AppBarPageLink extends Component {
  state = {
      anchorEl: null,
      openMenu: false,
      usingMenu: false,
      timeoutId: null,
  }
  componentWillUnmount () {
      clearTimeout(this.state.timeoutId)
  }
  handleButtonMouseEnter = (e) => {
      this.setState({
          openMenu: true,
          anchorEl: e.currentTarget,
      })
  }
  handleButtonMouseLeave = () => {
      if (this.state.usingMenu) {
          return
      }
      const timeoutId = setTimeout(() => {
          if (this.state.usingMenu) {
              return
          }
          this.setState({ openMenu: false, anchorEl: null })
      }, 100)
      this.setState({ timeoutId })
  }
  handleMenuMouseEnter = () => this.setState({ usingMenu: true })
  handleMenuMouseLeave = () => {
      this.setState({ openMenu: false, anchorEl: null, usingMenu: false })
  }
  handleCloseMenu = () => this.setState({ openMenu: false })
  render () {
      const {
          nameEn,
          nameFr,
          link,
          indexLang,
          className,
          color,
      } = this.props
      return (
          <NavLink to={`/${link}`} activeClassName="active-nav" style={{ textDecoration: 'none' }} className={className}>
              <Button style={{ backgroundColor: color }}>
                  <b>{indexLang ? nameFr : nameEn}</b>
              </Button>
          </NavLink>
      )
  }
}

export default AppBarPageLink
