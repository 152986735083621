const products = (state = {
    products: [],
}, action) => {
    switch (action.type) {
        case 'SET_PRODUCT_PRICE': {
            return {
                products: action.payload,
            }
        }
        default:
            return state
    }
}

export default products
