import React, { Component } from 'react'
import { connect } from 'react-redux'
import { PK_STRIPE, DEVMODE } from '../../constant/otherConst';

const orderAddContainer = (ComposedComponent) => {
    class OrderAddContainer extends Component {
        state = {};
        static getDerivedStateFromProps(props) {
            const cart = localStorage.getItem('cart')
            if (!cart) {
                props.history.push('/box')
            }
            return null;
        }

        render() {
            return (
                <ComposedComponent {...this.props} />
            )
        }
    }
    const mapStateToProps = ({
        carts: { isFetching: cartIsFetching, cart, errorCoupon, submitSucceededCoupon, submittingCoupon },
        user: { isFetching: userIsFetching, addresses },
        langs: { indexLang },
        orders,
    }) => ({
        addresses,
        cart,
        stripePk: PK_STRIPE[DEVMODE ? 0 : 1],
        indexLang,
        isFetching: cartIsFetching || userIsFetching,
        orders,
        errorCoupon,
        submitSucceededCoupon,
        submittingCoupon,
    })
    return connect(mapStateToProps)(OrderAddContainer)
}

export default orderAddContainer
