import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import { YES, NO } from '../../constant/stringTraduction';
import './alert.css';

const AlertModal = ({ indexLang, confirmation, toggleModal, modal, title, text, onConfirm, alternateBouttonText }) => {
    return (
        <Modal id='alert-modal' isOpen={modal} toggle={toggleModal} backdrop='static' >
            <ModalHeader className='alert-header' toggle={toggleModal} >{title[indexLang]}</ModalHeader>
            <ModalBody className='alert-body' >
                {text[indexLang]}
            </ModalBody>
            <ModalFooter className='alert-footer' >
                {confirmation ?
                    <div>
                        <Button className="black-button alert-button" onClick={toggleModal} >
                            {NO[indexLang]}
                        </Button>
                        <Button className="yellow-button alert-button" onClick={onConfirm} >
                            {YES[indexLang]}
                        </Button>
                    </div>
                    :
                    <Button className="yellow-button alert-button" onClick={toggleModal} >
                        {alternateBouttonText ? alternateBouttonText[indexLang] : 'Ok'}
                    </Button>
                }
            </ModalFooter>
        </Modal>
    );
}

export default AlertModal
