import { Component } from 'react'

class ScrollToTopOnMount extends Component {
    componentDidUpdate () {
        if (window.location.href.indexOf('#') === -1) {
            window.scrollTo(0, 0);
        }
    }
    render () {
        return this.props.children;
    }
}

export default ScrollToTopOnMount
