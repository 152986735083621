import Button from '@material-ui/core/Button';
import React, { Component } from 'react';
import { fetchDelete, fetchUpdate } from '../../actions/users';
import { VALIDATIONSUPRESSION } from '../../constant/stringTraduction';
import history from '../../containers/routers/history';
import AlertModal from '../alert/AlertModal';

const styles = {
    card: {
        margin: 16,
    },
    cardContainer: {
        display: 'flex',
        flexFlow: 'row wrap',
    },
    p: {
        flex: '3 3 auto',
        padding: '16px 8px',
    },
}

class AdminUsersItem extends Component {
  state = {
      elevation: 1,
  }
  handleEdit = () => {
      const { user: { _id } } = this.props
      return history.push(`/admin/users/edit/${_id}`)
  }
  onConfirm = async () => {
      const { dispatch, _id } = this.props;
      await dispatch({ type: 'CLOSE_ALERT' });
      dispatch(fetchDelete(_id))
  }
  handleFormSubmit = (values) => {
      const { dispatch, user: { _id } } = this.props
      return dispatch(fetchUpdate(_id, values))
  }
  handleMouseEnter = () => this.setState({ elevation: 4 })
  handleMouseLeave = () => this.setState({ elevation: 1 })
  render () {
      const {
          user: {
              values: {
                  email,
              },
          },
      } = this.props
      return (
          <div
              style={{ zDepth: this.state.elevation, ...styles.card }}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
          >
              <AlertModal
                  indexLang={0}
                  confirmation={'confirmation'}
                  toggleModal={this.toggleModal}
                  modal={true}
                  title={'valdl'}
                  text={VALIDATIONSUPRESSION}
                  onConfirm={this.onConfirm}
              />
              <div style={styles.p}>
                  <p>{email}</p>
              </div>

              <div className="AdminUsersItemButtons">
                  <Button
                      onClick={this.handleEdit}
                      type="button"
                      label="Edit"
                      style={{ margin: 4 }}
                      primary={true}
                  />
                  <Button
                      onClick={this.handleDelete}
                      type="button"
                      label="X"
                      className="delete-button"
                      style={{ margin: 4 }}
                      primary={true}
                  />
              </div>
          </div>
      )
  }
}

export default AdminUsersItem
