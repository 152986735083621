import React, { Component } from 'react';
import catsmall from '../../../assets/selection/cat_small_150.png'
import { TEXTECHATSMALL } from '../../../constant/stringTraduction';
export default class CatSmallPicto extends Component {
    render () {
        return (
            <div style={{ marginTop: -24 }}>
                <img src={catsmall} alt="chat small 0-9 lbs"></img>
                <div><b>{TEXTECHATSMALL[this.props.indexLang]}</b></div>
                <div>0-9 lbs</div>
            </div>
        );
    }
}
