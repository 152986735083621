import React, { Component } from 'react'
import { connect } from 'react-redux'

import './user.css'

const userHOC = (ComposedComponent) => {
    class UserHOC extends Component {
        render() {
            const {
                isFetching,
            } = this.props
            return (
                isFetching ? null : <ComposedComponent {...this.props} />
            )
        }
    }
    const mapStateToProps = ({
        user,
        langs: { indexLang },
    }, {
        match: { params: { resetToken } },
    }) => ({
        isFetching: user.isFetching,
        resetToken,
        user,
        indexLang,
    })
    return connect(mapStateToProps)(UserHOC)
}

export default userHOC
