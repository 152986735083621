import React, { Component } from 'react'
import { connect } from 'react-redux'

import './user.css'

const userContainer = (ComposedComponent) => {
    class UserContainer extends Component {
        render() {
            const {
                isFetching,
            } = this.props
            return (
                isFetching ? null : <ComposedComponent {...this.props} />
            )
        }
    }
    const mapStateToProps = ({
        user,
        langs: { indexLang },
    }) => ({
        isFetching: user.isFetching ? true : false,
        user,
        indexLang,
    })
    return connect(mapStateToProps)(UserContainer)
}

export default userContainer
