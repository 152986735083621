import React from 'react'
import langContainer from '../../containers/lang/langContainer';
import { setLang } from '../../actions/lang';
import './lang.css';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button'
class Lang extends React.Component {
    getChildren () {
        const {
            indexLang,
        } = this.props;

        if (indexLang === 0) {
            return <div className="menu-item-lang" key={1}>FR</div>;
        } else if (indexLang === 1) {
            return <div className="menu-item-lang" key={1}>EN</div>;
        }
    }

    dispatchLang () {
        const {
            indexLang,
            dispatch,
            _id,
        } = this.props;

        if (indexLang === 0) {
            dispatch(setLang(1, _id));
        } else if (indexLang === 1) {
            dispatch(setLang(0, _id));
        }
    }

    render () {
        const {
            indexLang,
            menu,
        } = this.props;

        if (menu === true) {
            return (
                <ListItem
                    button={true}
                    onClick={() => this.dispatchLang()}
                >
                    <ListItemText primary={indexLang ? 'EN' : 'FR'} />
                </ListItem>
            )
        } else {
            return (
                <Button
                    className="lang"
                    onMouseEnter={this.handleButtonMouseEnter}
                    onMouseLeave={this.handleButtonMouseLeave}
                    style={{ minWidth: 'none', margin: '0' }}
                    label={{ padding: '0 0 2px 0' }}
                    onClick={() => this.dispatchLang()}
                >{indexLang ? 'EN' : 'FR'}</Button>
            );
        }
    }
}

export default langContainer(Lang)
