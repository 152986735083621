import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Row, Input } from 'reactstrap';
import { fetchSendMessage } from '../../actions/faq';
import {
    YOURNAME, YOUREMAIL, YOURMESSAGE, SEND, FAQABOUTPACKS, FAQHOWDOESWORK, SHIPPING,
    FAQHOWDOESWORKCONTENT1, FAQHOWDOESWORKCONTENT2, FAQHOWDOESWORKCONTENT3, FAQHOWDOESWORKCONTENT4,
    FAQCANICHOOSE, FAQCANICHOOSECONTENT1, FAQCANICHOOSECONTENT2, FAQCANICHOOSECONTENT3, FAQHOWCANCEL,
    FAQHOWCANCELCONTENT1, FAQCANTRIAL, FAQCANTRIALCONTENT1, FAQCANTRIALCONTENT2, FAQCANTRIALCONTENT3,
    FAQSHIPPINGCONTENT1, FAQSHIPPINGCONTENT2, FAQSHIPPINGCONTENT3, FAQSHIPPINGCONTENT4, FAQSHIPPINGCONTENT5,
    FAQSHIPPINGCONTENT6, SUBSCRIPTIONCAL, FAQSHIPWORLD, FAQSHIPWORLDCONTENT1, FAQWILLDELIVER,
    FAQWILLDELIVERCONTENT1, FAQWHATHAPPENS, FAQWHATHAPPENSCONTENT1, FAQCANITRACK, FAQCANITRACKCONTENT1,
    COMMANDES, FAQWHENANDHOWMUCH, FAQWHENANDHOWMUCHCONTENT1, FAQWHENANDHOWMUCHCONTENT2, FAQWHENANDHOWMUCHCONTENT3,
    FAQCANCELORDER, FAQCANCELORDERCONTENT1, FAQCANCELORDERCONTENT2, FAQACCOUNTO, FAQACCOUNTOCONTENT1,
    FAQPAYMENTMETHODS, FAQPAYMENTMETHODSCONTENT1, FAQRETURNS, FAQIFIDONT, FAQIFIDONTCONTENT1,
    FAQIFIDONTCONTENT2, FAQIFIDONTCONTENT3, FAQIFIDONTCONTENT4, RETURNPOLICYPAGE, FAQIFIDONTCONTENT6,
    FAQWHATIFDEF, FAQWHATIFDEFCONTENT1, FAQWHATIFDEFCONTENT2, FAQWHATIFDEFCONTENT3, FAQWHATIFDEFCONTENT4,
    FAQCOUPONSDISCOUNTS, FAQCOUPONSDISCOUNTSCONTENT1, FAQCOUPONSDISCOUNTSCONTENT2, MESSAGESENT, FAQHOWCANCELCONTENT2,
    FAQHOWCANCELCONTENT3, FAQPRICEADJUSMENT, FAQPRICEADJUSMENTCONTENT1, FAQREFERTITLE1, FAQREFERTITLE2,
    FAQREFERTITLE3, FAQREFERTITLE4, FAQREFERTITLE5, FAQREFERMAINTITLE, WINAPACK, NOW, REFERENCETAB,
    FAQREFERTEXT1, FAQREFERTEXT1A, FAQREFERTEXT2, FAQREFERTEXT3, FAQREFERTEXT4, FAQREFERTEXT5, FAQREFERTEXT5A,
    FAQREFERNOTE, FAQREFERTEXT6, FAQYOURORDERS, TEAMHOLIDAYBACK,
} from '../../constant/stringTraduction';
import faqContainer from '../../containers/faq/faqContainer';
import './faq.css';
import imgHeaderEnglish from '../../assets/contact_us_english.jpg';
import imgHeaderFrench from '../../assets/contact_us_french.jpg';
import AlertModal from '../alert/AlertModal';
import { SUPPORT_EMAIL } from '../../constant/otherConst';
import { getHolidays2019, getNoMoreOrders, getClosed } from '../../utils/specialEvents';

class Faq extends React.Component {
    constructor() {
        super();
        this.state = {
            name: '',
            email: '',
            content: '',
        }
    }
    componentDidMount() {
        if (window.location.hash) {
            const element = document.querySelector(`${window.location.hash}`);
            if (element) {
                element.scrollIntoView();
            }
        }
    }
    handleGoToOrders = () => {
        const { dispatch, history } = this.props;
        dispatch({ type: 'SWITCH_TAB', indexTab: '3' });
        history.push('/user/profile');
    }
    handleGoToRefer = () => {
        const { dispatch, history } = this.props;
        dispatch({ type: 'SWITCH_TAB', indexTab: '4' });
        history.push('/user/profile');
    }

    render() {
        const {
            dispatch,
            indexLang,
            isSending,
            succeeded,
            modal,
            title,
            text,
        } = this.props;
        return (
            <div>
                <Container className='white-background container-top-margin faq-container' style={{ textAlign: 'center', paddingTop: 87 }}>
                    {(!getClosed() && <Row style={{ marginBottom: 20 }}><Col lg='12'><Col lg='12' className='background-image-header' style={{ backgroundImage: `url(${indexLang ? imgHeaderFrench : imgHeaderEnglish})` }}></Col></Col></Row>)}
                    <Row style={{ textAlign: 'left' }}>
                        {(!getClosed() &&
                            <Col md='12'>
                                <Input className='form-line' placeholder={YOURNAME[indexLang]} value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                                <Input className='form-line' placeholder={YOUREMAIL[indexLang]} value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                                <Input className='form-line' type='textarea' placeholder={YOURMESSAGE[indexLang]} value={this.state.content} onChange={(e) => this.setState({ content: e.target.value })} />
                                {getHolidays2019() ? <div className="holiday-away-message">{TEAMHOLIDAYBACK[indexLang]}</div> : ''}
                                <Button disabled={succeeded} className='yellow-button' style={{ width: '100%' }} onClick={() => {
                                    dispatch(fetchSendMessage({ name: this.state.name, email: this.state.email, content: this.state.content }));
                                }}>
                                    {isSending ? <CircularProgress size={24} style={{ verticalAlign: 'middle', color: '#000000' }} /> : succeeded ? MESSAGESENT[indexLang] : SEND[indexLang]}
                                </Button>
                            </Col>
                        )}
                        <Col md='12'>
                            <div id='faq' style={{ fontSize: 24, fontWeight: 'bold', marginTop: 20 }}>FAQ</div>
                            <div className='faq-title'>{FAQABOUTPACKS[indexLang]}</div>
                            <div className='faq-subtitle'>{FAQHOWDOESWORK[indexLang]}</div>
                            <div className='faq-content'>{FAQHOWDOESWORKCONTENT1[indexLang]}<Link to='/learn-more'>{FAQHOWDOESWORKCONTENT2[indexLang]}</Link>{FAQHOWDOESWORKCONTENT3[indexLang]}<a href='https://www.facebook.com/beonebreed/'>BeOneBreed</a>{FAQHOWDOESWORKCONTENT4[indexLang]}<a href={'mailto:' + SUPPORT_EMAIL}>{SUPPORT_EMAIL}</a></div>

                            <div className='faq-subtitle'>{FAQCANICHOOSE[indexLang]}</div>
                            <div className='faq-content'>{FAQCANICHOOSECONTENT1[indexLang]}<a href={'mailto:' + SUPPORT_EMAIL}>{SUPPORT_EMAIL}</a>{FAQCANICHOOSECONTENT2[indexLang]}<br /><br />{FAQCANICHOOSECONTENT3[indexLang]}</div>

                            {!getNoMoreOrders() &&
                            <div>
                                <div className='faq-subtitle'>{FAQHOWCANCEL[indexLang]}</div>
                                <div className='faq-content'>{FAQHOWCANCELCONTENT1[indexLang]}<span className="underlineLink" onClick={this.handleGoToOrders}>{FAQYOURORDERS[indexLang]}</span>{FAQHOWCANCELCONTENT2[indexLang]}<Link to='/learn-more#subscription_calendar'>{SUBSCRIPTIONCAL[indexLang]}</Link>{FAQHOWCANCELCONTENT3[indexLang]}.</div>

                                <div className='faq-subtitle'>{FAQCANTRIAL[indexLang]}</div>
                                <div className='faq-content'>{FAQCANTRIALCONTENT1[indexLang]}<span className="underlineLink" onClick={this.handleGoToOrders}>{FAQYOURORDERS[indexLang]}</span>{FAQCANTRIALCONTENT2[indexLang]}{!getNoMoreOrders() && <Link to='/box'>{FAQCANTRIALCONTENT3[indexLang]}</Link>}</div>
                            </div>
                            }

                            <div className='faq-title'>{SHIPPING[indexLang].toUpperCase()}</div>
                            <div className='faq-subtitle'>{FAQSHIPPINGCONTENT1[indexLang]}</div>
                            <div className='faq-content'><i>{FAQSHIPPINGCONTENT2[indexLang]}</i><br />{FAQSHIPPINGCONTENT3[indexLang]}<Link to='/learn-more#subscription_calendar'>{SUBSCRIPTIONCAL[indexLang]}</Link>{FAQSHIPPINGCONTENT4[indexLang]}<br /><br /><i>{FAQSHIPPINGCONTENT5[indexLang]}</i><br />{FAQSHIPPINGCONTENT6[indexLang]}</div>

                            <div className='faq-subtitle'>{FAQSHIPWORLD[indexLang]}</div>
                            <div className='faq-content'>{FAQSHIPWORLDCONTENT1[indexLang]}</div>

                            <div className='faq-subtitle'>{FAQWILLDELIVER[indexLang]}</div>
                            <div className='faq-content'>{FAQWILLDELIVERCONTENT1[indexLang]}</div>

                            <div className='faq-subtitle'>{FAQWHATHAPPENS[indexLang]}</div>
                            <div className='faq-content'>{FAQWHATHAPPENSCONTENT1[indexLang]}</div>

                            <div className='faq-subtitle'>{FAQCANITRACK[indexLang]}</div>
                            <div className='faq-content'>{FAQCANITRACKCONTENT1[indexLang]}</div>

                            <div className='faq-title'>{COMMANDES[indexLang].toUpperCase()}</div>
                            <div className='faq-subtitle'>{FAQWHENANDHOWMUCH[indexLang]}</div>
                            <div className='faq-content'>{FAQWHENANDHOWMUCHCONTENT1[indexLang]}<Link to='/learn-more#subscription_calendar'>{FAQWHENANDHOWMUCHCONTENT2[indexLang]}</Link>{FAQWHENANDHOWMUCHCONTENT3[indexLang]}<Link to='/learn-more#subscription_calendar'>{FAQWHENANDHOWMUCHCONTENT2[indexLang]}</Link></div>

                            {!getNoMoreOrders() &&
                            <div>
                                <div className='faq-subtitle'>{FAQCANCELORDER[indexLang]}</div>
                                <div className='faq-content'>{FAQCANCELORDERCONTENT1[indexLang]}<a href={'mailto:' + SUPPORT_EMAIL}>{SUPPORT_EMAIL}</a>{FAQCANCELORDERCONTENT2[indexLang]}</div>
                            </div>
                            }

                            <div className='faq-subtitle'>{FAQACCOUNTO[indexLang]}</div>
                            <div className='faq-content'>{FAQACCOUNTOCONTENT1[indexLang]}</div>

                            <div className='faq-subtitle'>{FAQPAYMENTMETHODS[indexLang]}</div>
                            <div className='faq-content'>{FAQPAYMENTMETHODSCONTENT1[indexLang]}</div>

                            <div className='faq-title'>{FAQRETURNS[indexLang]}</div>
                            <div className='faq-subtitle'>{FAQIFIDONT[indexLang]}</div>
                            <div className='faq-content'>{FAQIFIDONTCONTENT1[indexLang]}<br /><br />{FAQIFIDONTCONTENT2[indexLang]}<b>{FAQIFIDONTCONTENT3[indexLang]}</b>{FAQIFIDONTCONTENT4[indexLang]}<Link to='/return-policy'>{RETURNPOLICYPAGE[indexLang]}</Link>{FAQIFIDONTCONTENT6[indexLang]}</div>

                            <div className='faq-subtitle'>{FAQWHATIFDEF[indexLang]}</div>
                            <div className='faq-content'>{FAQWHATIFDEFCONTENT1[indexLang]}<b>{FAQWHATIFDEFCONTENT2[indexLang]}</b>{FAQWHATIFDEFCONTENT3[indexLang]}<Link to='/return-policy'>{RETURNPOLICYPAGE[indexLang]}</Link>{FAQWHATIFDEFCONTENT4[indexLang]}</div>

                            <div className='faq-title'>{FAQCOUPONSDISCOUNTS[indexLang]}</div>
                            <div className='faq-content'>{FAQCOUPONSDISCOUNTSCONTENT1[indexLang]}<br /><br />{FAQCOUPONSDISCOUNTSCONTENT2[indexLang]}</div>

                            <div className='faq-subtitle'>{FAQPRICEADJUSMENT[indexLang]}</div>
                            <div id='referral'></div>
                            <div className='faq-content'>{FAQPRICEADJUSMENTCONTENT1[indexLang]}<a href={'mailto:' + SUPPORT_EMAIL}>{SUPPORT_EMAIL}</a>.</div>

                            <div className='faq-title'>{FAQREFERMAINTITLE[indexLang]}</div>

                            <div className='faq-subtitle'>{FAQREFERTITLE1[indexLang]}</div>
                            <div className='faq-content'>{FAQREFERTEXT1[indexLang]} <Link to='/referral-program'>{NOW[indexLang]}</Link> {FAQREFERTEXT1A[indexLang]}.</div>

                            <div className='faq-subtitle'>{FAQREFERTITLE2[indexLang]}</div>
                            <div className='faq-content'>{FAQREFERTEXT2[indexLang]}.</div>

                            <div className='faq-subtitle'>{FAQREFERTITLE3[indexLang]}</div>
                            <div className='faq-content'>{FAQREFERTEXT3[indexLang]}.</div>
                            <div className='faq-content'>{FAQREFERTEXT6[indexLang]}.</div>

                            <div className='faq-subtitle'>{FAQREFERTITLE4[indexLang]}</div>
                            <div className='faq-content'>{FAQREFERTEXT4[indexLang]} <b>{WINAPACK[indexLang]}</b></div>
                            <div className='faq-note'><em>*{FAQREFERNOTE[indexLang]}.</em></div>

                            <div className='faq-subtitle'>{FAQREFERTITLE5[indexLang]}</div>
                            <div className='faq-content'>{FAQREFERTEXT5[indexLang]} <span onClick={() => this.handleGoToRefer()} style={{ textDecoration: 'underline', cursor: 'pointer' }} to='/user/profile'>{REFERENCETAB[indexLang]}</span> {FAQREFERTEXT5A[indexLang]}.</div>

                        </Col>
                        <AlertModal
                            indexLang={indexLang}
                            confirmation={false}
                            toggleModal={() => dispatch({ type: 'CLOSE_ALERT' })}
                            modal={modal}
                            title={title}
                            text={text}
                        />
                    </Row>
                </Container >
            </div>
        );
    }
}

export default faqContainer(Faq)
