import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import SuccessableButton from '../../components/buttons/SuccessableButton';
import renderTextFieldBlack from '../../components/fields/renderTextFieldBlack';
import { MODIFIERMOTDEPASSE, USERMODIFIER, CONFIRMATIONMDP, NOUVEAUMOTDEPASSE, MOTDEPASSEACTUEL } from '../../constant/stringTraduction';
import './user.css';

const validate = (values) => {
    const errors = {}
    const requiredFields = ['currentPassword', 'password', 'passwordConfirm']
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = ['*', '*'];
        }
    })

    if (values.password && values.password === values.currentPassword) {
        errors.password = ['New password must be different', 'Le nouveau mot de passe doit être différent'];
    }
    if (values.password !== values.passwordConfirm) {
        errors.passwordConfirm = ['Must match password', 'Doit correspondre au mot de passe'];
    }
    if (values && values.currentPassword && values.currentPassword.length < 8) {
        errors.currentPassword = ['8 characters minimum', '8 caractères minimum'];
    }
    if (values && values.password && values.password.length < 8) {
        errors.password = ['8 characters minimum', '8 caractères minimum'];
    }
    if (values && values.passwordConfirm && values.passwordConfirm.length < 8) {
        errors.passwordConfirm = ['8 characters minimum', '8 caractères minimum'];
    }
    return errors
}

class UserProfilePwForm extends Component {
    render() {
        const {
            handleSubmit,
            invalid,
            onFormSubmit,
            pristine,
            submitSucceeded,
            submitting,
            indexLang,
            user,
            resetError,
        } = this.props
        return (
            <div className="UserProfileForm">
                <form onSubmit={handleSubmit(onFormSubmit)}>
                    <div className="field-container">
                        <Field
                            className="field"
                            name="currentPassword"
                            color='black'
                            component={renderTextFieldBlack}
                            label={MOTDEPASSEACTUEL[indexLang]}
                            type="password"
                            indexLang={indexLang} />
                        <Field
                            className="field"
                            name="password"
                            color='black'
                            component={renderTextFieldBlack}
                            label={NOUVEAUMOTDEPASSE[indexLang]}
                            type="password"
                            indexLang={indexLang} />
                        <Field
                            className="field"
                            name="passwordConfirm"
                            color='black'
                            component={renderTextFieldBlack}
                            label={CONFIRMATIONMDP[indexLang]}
                            type="password"
                            indexLang={indexLang} />
                    </div>
                    <div className="button-container" style={{ marginBottom: 10 }}>
                        <SuccessableButton
                            disabled={pristine || invalid}
                            error={user && user.error && user.error[indexLang]}
                            label={MODIFIERMOTDEPASSE[indexLang]}
                            reset={resetError}
                            submitSucceeded={submitSucceeded}
                            submitting={submitting}
                            successLabel={USERMODIFIER[indexLang]}
                        />
                    </div>
                </form>
            </div>
        )
    }
}

export default reduxForm({
    form: 'user_password',
    validate,
    destroyOnUnmount: false,
})(UserProfilePwForm)
