export const normalizePhone = (value) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 3) {
        return onlyNums
    }
    if (onlyNums.length <= 7) {
        return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`
    }
    return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`
}

export const normalizeState = (value) => {
    if (!value) {
        return value
    }
    const toUpperCase = value.toUpperCase()
    if (toUpperCase.length <= 2) {
        return toUpperCase
    }
}

export const normalizeZip = (value) => {
    if (!value) {
        return value
    }
    if (!value.charAt(0).match(/[ABCEGHJKLMNPRSTVXYabceghjklmnprstvxy]/g)) {
        return value.substring(0, 0);
    }
    if (!value.charAt(1).match(/[0-9]/g)) {
        return value.substring(0, 1);
    }
    if (!value.charAt(2).match(/[ABCEGHJKLMNPRSTVWXYZabceghjklmnprstvwxyz]/g)) {
        return value.substring(0, 2);
    }
    if (!value.charAt(3).match(/[0-9]/g)) {
        return value.substring(0, 3);
    }
    if (!value.charAt(4).match(/[ABCEGHJKLMNPRSTVWXYZabceghjklmnprstvwxyz]/g)) {
        return value.substring(0, 4);
    }
    if (!value.charAt(5).match(/[0-9]/g)) {
        return value.substring(0, 5);
    }
  
    if (value.length <= 6) {
        return value
    }
}
