import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


const headerContainer = (ComposedComponent) => {
    class HeaderContainer extends Component {
        render () {
            return (
                this.props.isFetching ? null : <ComposedComponent {...this.props} />
            )
        }
    }
    const mapStateToProps = ({
        carts: { cart },
        drawer,
        user: { isFetching: userIsFetching, roles, values: { firstName } },
        langs: { indexLang },
    }) => ({
        cart,
        drawer,
        firstName,
        isAdmin: roles.some((role) => role === 'admin') ? true : false,
        isOwner: roles.some((role) => role === 'owner') ? true : false,
        isFetching: userIsFetching ? true : false,
        indexLang,
    })

    return withRouter(connect(mapStateToProps)(HeaderContainer))
}

export default headerContainer
