export const setQuestionCpt = (p_newIndex) => {
    return {
        type: 'SET_COUNTER',
        payload: p_newIndex,
    }
}

export const setQuestionCptAvecReponse = (p_indexReponse, p_reponse) => {
    if (p_indexReponse === 0) {
        return {
            type: 'SET_COUNTER_REPONSE_FIRST',
            payload: {
                indexReponse: 0,
                indexAnimal: p_reponse,
                reponse: p_reponse,
            },
        }
    }
    return {
        type: 'SET_COUNTER_REPONSE',
        payload: {
            indexReponse: p_indexReponse,
            reponse: p_reponse,
        },
    }
}

export const setNeckSize = (indexQuestion, neckSize, standardNeckSize) => {
    return {
        type: 'SET_NECK_SIZE',
        payload: {
            neckSize: neckSize,
            indexQuestion: indexQuestion,
            standardNeckSize: standardNeckSize,
        },
    }
}

