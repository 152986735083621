import React, { Component } from 'react';
import { Col, Container, Row, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import {
    COMMENTCAFONCTIONNE1, COMMENTCAFONCTIONNE2,
    COMMENTCAFONCTIONNE3, CHOOSEPACK, RECEIVEPACK, PARTYSTARTED,
    CALENDARSEASONS, PERSONNALISEPLAN, PERSONNALISEPLAN2, ABONNEMENTDEBUTEA,
    ABONNEMENTDEBUTEA2, RECEVEZCHAQUESAISON, GETWINTERPACK, GETSUMMERPACK,
    RECEPTIONDATEVARY, STARTTHEPARTY, SUBSCRIPTIONCAL,
    STARTINGAT, STARTINGPRICEDISCOVERY, STARTINGPRICESUPER, STARTINGPRICESUPERBED,
    WEDONTSPOIL, WEDONTSPOILCONTENT1, WEDONTSPOILCONTENT2, SEEDETAILS,
    PACKSNEAKPEAK, HIVER, ETE, VALUEOF, PRICE,
} from '../../constant/stringTraduction';
import learnMoreContainer from '../../containers/learnmore/learnMoreContainer';
import UnboxingsGallery from './UnboxingsGallery';
import './learnMore.css';
import calendar_english from '../../assets/calendrier_2019_EN.png';
import calendar_french from '../../assets/calendrier_2019_FR.png';

import pack_discovery_english from '../../assets/bulle_discovery_EN_1.png';
import pack_discovery_french from '../../assets/bulle_discovery_FR_1.png';
import pack_superpartypack_english from '../../assets/bulle_discovery_EN_2.png';
import pack_superpartypack_french from '../../assets/bulle_discovery_FR_2.png';
import pack_superpartypack_bed_english from '../../assets/bulle_discovery_EN_3.png';
import pack_superpartypack_bed_french from '../../assets/bulle_discovery_FR_3.png';

import LearnMoreModal from './LearnMoreModal';
import { getNextPackDate } from '../../utils/dates';
import { getNoMoreOrders } from '../../utils/specialEvents';

class LearnMore extends Component { 
    constructor() {
        super();
        this.state = {
            modal: false,
            indexPack: 0,
            indexAnimal: 1,
            indexSaison: 0,
            fullPacksSelection: false,
        }
        this.toggleModal = this.toggleModal.bind(this);
        this.setIndexPack = this.setIndexPack.bind(this);
        this.openFullSelection = this.openFullSelection.bind(this);
        this.changeAnimal = this.changeAnimal.bind(this);
        this.setIndexPackFullSelection = this.setIndexPackFullSelection.bind(this);
    }

    componentDidMount() {
        const { indexSaison } = getNextPackDate();
        this.setState({ indexSaison: indexSaison });
        if (window.location.hash) {
            const element = document.querySelector(`${window.location.hash}`);
            if (element && window.location.hash === '#previous-packs') {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        }
    }

    changeAnimal() {
        this.setState({ indexAnimal: !this.state.indexAnimal ? 1 : 0 });
    }

    setIndexPack(p_indexPack) {
        this.setState({ fullPacksSelection: false, indexPack: p_indexPack });
        this.toggleModal();
    }

    setIndexPackFullSelection(p_indexPack) {
        this.setState({ indexPack: p_indexPack });
    }

    openFullSelection() {
        this.setState({ fullPacksSelection: true });
        this.toggleModal();
    }

    toggleModal() {
        this.setState({ modal: !this.state.modal });
    }

    scrollToCalender() {
        const myElement = document.getElementById('subscription_calendar');
        const topPos = myElement.offsetTop;

        window.scrollTo({
            top: topPos,
            left: 0,
            behavior: 'smooth',
        });
    }

    getValueString(indexType) {
        const valeurs = [55, 75, 85];
        const { indexLang } = this.props;
        return (indexLang ? VALUEOF[indexLang] : ' $') + (valeurs[indexType]) + (indexLang ? '$' : VALUEOF[indexLang]);
    }

    render() {
        const { indexLang } = this.props;
        const pack_images = [
            [pack_discovery_english, pack_superpartypack_english, pack_superpartypack_bed_english],
            [pack_discovery_french, pack_superpartypack_french, pack_superpartypack_bed_french],
        ];

        const saison = [HIVER[indexLang], ETE[indexLang]][this.state.indexSaison];
        let sneakpeek = saison + PACKSNEAKPEAK[indexLang];
        if (indexLang === 1) {
            let de = 'd\''
            sneakpeek = PACKSNEAKPEAK[indexLang] + de + saison.toLowerCase();
        }

        return (
            <div style={{ textAlign: 'center', marginTop: '64px' }}>
                <div style={{ backgroundColor: '#000' }}>
                    <Container className='explication-container desktop-only' style={{ paddingBottom: 30 }}>
                        <div>
                            <h2 style={{ marginBottom: 20 }} className='explication_more'>{COMMENTCAFONCTIONNE1[indexLang]}<span style={{ fontWeight: '800', fontSize: '38px' }}>{COMMENTCAFONCTIONNE2[indexLang]}</span>{COMMENTCAFONCTIONNE3[indexLang]}</h2>
                            <Row>
                                <Col sm="4">
                                    <div className="first-step explication_more learn-more-step">1.</div>
                                    <h2 className='explication-title explication_more'>{CHOOSEPACK[indexLang]}</h2>
                                    <h3 className='explication-text explication_more'>{PERSONNALISEPLAN[indexLang]}<br />{PERSONNALISEPLAN2[indexLang]}</h3>
                                    <br />
                                </Col>
                                <Col sm="4">
                                    <div className="second-step explication_more learn-more-step">2.</div>
                                    <h2 className='explication-title explication_more'>{RECEIVEPACK[indexLang]}</h2>
                                    <h3 className='explication-text explication_more'>{ABONNEMENTDEBUTEA[indexLang]} <span className='link' onClick={this.scrollToCalender}>{ABONNEMENTDEBUTEA2[indexLang]}</span></h3>
                                    <br />
                                </Col>
                                <Col sm="4">
                                    <div className="third-step explication_more learn-more-step">3.</div>
                                    <h2 className='explication-title explication_more'>{PARTYSTARTED[indexLang]}</h2>
                                    <h3 className='explication-text explication_more'>{RECEVEZCHAQUESAISON[indexLang]}</h3>
                                    <br />
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                <div className='learn-more-two-color'>
                    <Container>
                        <Row>
                            <Col md="4">
                                <Button
                                    className="blue-button"
                                    size="lg"
                                    style={{
                                        marginTop: 20,
                                        padding: '10px 30px',
                                        fontFamily: 'Gotham-Black',
                                        border: 'none',
                                        backgroundColor: '#fff100',
                                    }}
                                    onClick={this.openFullSelection}>
                                    {sneakpeek}
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <LearnMoreModal
                                indexSaison={this.state.indexSaison}
                                indexLang={indexLang}
                                modal={this.state.modal}
                                toggleModal={this.toggleModal}
                                indexPack={this.state.indexPack}
                                indexAnimal={this.state.indexAnimal}
                                changeAnimal={this.changeAnimal}
                                fullPacksSelection={this.state.fullPacksSelection}
                                setIndexPack={this.setIndexPackFullSelection}
                            />
                            <Col lg='4'>
                                <img src={pack_images[indexLang][0]} id={'pack_discovery_' + indexLang} className='pack-image' onClick={() => this.setIndexPack(0)} alt="Discovery" />
                                <div className='pack-description'>{STARTINGAT[indexLang]}<div className='pack-price'>{STARTINGPRICEDISCOVERY[indexLang]}/pack</div></div>
                                <div className='pack-button-container'><Button className="pack-button" size="lg" onClick={() => this.setIndexPack(0)}>{SEEDETAILS[indexLang]}</Button></div>
                            </Col>
                            <Col lg='4'>
                                <img src={pack_images[indexLang][1]} id={'pack_superpartypack_' + indexLang} className='pack-image' onClick={() => this.setIndexPack(1)} alt="Super party pack" />
                                <div className='pack-description'>{STARTINGAT[indexLang]}<div className='pack-price'>{STARTINGPRICESUPER[indexLang]}/pack</div></div>
                                <div className='pack-button-container'><Button className="pack-button" size="lg" onClick={() => this.setIndexPack(1)}>{SEEDETAILS[indexLang]}</Button></div>
                            </Col>
                            <Col lg='4'>
                                <img src={pack_images[indexLang][2]} id={'pack_superpartypackbed_' + indexLang} className='pack-image' onClick={() => this.setIndexPack(2)} alt="Super party pack with bed" />
                                <div className='pack-description'>
                                    {PRICE[indexLang]}
                                    <div className='pack-price pack-price-normal'>{STARTINGPRICESUPERBED[indexLang]}</div>
                                </div>
                                <div className='pack-button-container'><Button className="pack-button" size="lg" onClick={() => this.setIndexPack(2)}>{SEEDETAILS[indexLang]}</Button></div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className='background-yellow'>
                    <Container className='dont-spoil-text'>
                        <b>{WEDONTSPOIL[indexLang]}</b>{WEDONTSPOILCONTENT1[indexLang]}<i style={{ fontSize: 'initial' }}>{WEDONTSPOILCONTENT2[indexLang]}</i>
                    </Container>
                    <div id='previous-packs'></div>
                    {!getNoMoreOrders() && <Link to="/box"><Button className="go-button" size="lg" style={{ marginTop: 20, fontWeight: 'bold', padding: '10px 40px', marginBottom: '25px' }}>GO</Button></Link>}
                </div>
                <UnboxingsGallery indexLang={indexLang} />
                <hr id='focus_unboxing' style={{ borderColor: '#fff100', marginBottom: 0, marginTop: 24, borderWidth: 24 }} />
                <div style={{ backgroundColor: 'black' }}>
                    <h2 className='learn-more-white-title'>{SUBSCRIPTIONCAL[indexLang]}</h2>
                </div>
                <div style={{ backgroundColor: 'white' }}>
                    <div className='arrow-down' />
                    <Container style={{ marginBottom: 20, marginTop: 20 }}>
                        <Row>
                            <Col lg='2'></Col>
                            <Col lg='8'><img style={{ marginTop: 10, width: '100%' }} src={indexLang ? calendar_french : calendar_english} alt={CALENDARSEASONS[indexLang]} /></Col>
                            <Col lg='2'></Col>
                        </Row>
                    </Container>
                    <Container className='calendar-explication-box'>
                        <div className='calendar-explication calendar-line' >{GETWINTERPACK[indexLang]}</div>
                        <div className='calendar-explication calendar-line' id='subscription_calendar' >{GETSUMMERPACK[indexLang]}</div>
                        <div className='calendar-explication' style={{ fontStyle: 'italic' }} >{RECEPTIONDATEVARY[indexLang]}</div>
                    </Container>
                    {!getNoMoreOrders() && <Link to="/box"><Button className="yellow-button" size="lg" style={{ marginTop: 20, fontSize: 20, fontWeight: 'bold', padding: '10px 40px' }}>{STARTTHEPARTY[indexLang]}</Button></Link>}
                    <div style={{ paddingBottom: 20 }}></div>
                </div>
            </div>
        );
    }
}

export default learnMoreContainer(LearnMore)
