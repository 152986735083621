import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import { YES, NO } from '../../constant/stringTraduction';
import './user.css';

const ResumSubModal = ({ indexLang, toggleModal, modal, title, text, onConfirm }) => {
    return (
        <Modal id='alert-modal' isOpen={modal} toggle={toggleModal} backdrop='static' >
            <ModalHeader className='alert-header' toggle={toggleModal} >{title[indexLang]}</ModalHeader>
            <ModalBody className='alert-body' >
                {text[indexLang]}
            </ModalBody>
            <ModalFooter className='alert-footer' >
                <div>
                    <Button className="black-button alert-button" onClick={toggleModal} >
                        {NO[indexLang]}
                    </Button>
                    <Button className="yellow-button alert-button" onClick={onConfirm} >
                        {YES[indexLang]}
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
}

export default ResumSubModal
