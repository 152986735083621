import React, { Component } from 'react';
import doglarge from '../../../assets/selection/dog_large_150.png'
import { TEXTECHIENLARGE } from '../../../constant/stringTraduction';
export default class DogLargePicto extends Component {
    render () {
        return (
            <div>
                <img src={doglarge} alt="dog large 50+ lbs"></img>
                <div><b>{TEXTECHIENLARGE[this.props.indexLang]}</b></div>
                <div>50+ lbs</div>
            </div>
        );
    }
}
