import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const style = () => ({
    cssLabel: {
        color: 'black !important',
    },
    cssUnderline: {
        color: 'white',
        '&:after': {
            borderBottomColor: '#000',
        },
        '&:before': {
            borderBottomColor: '#000',
        },
        '&:hover': {
            borderBottomColor: '#000',
        },
    },
})

const renderTextFieldBlack = ({ indexLang, classes, color, input, label, meta: { touched, error }, ...custom }) => {
    color = color ? color : '#fff';
    return (
        <FormControl {...custom}>
            <InputLabel style={{ color: color }}>
                {label}
            </InputLabel>
            <Input
                classes={{ underline: classes.cssUnderline }}
                error={touched && error !== undefined}
                {...input}
                {...custom}
                style={{ color: color }}
            />
            <FormHelperText>{error && error[indexLang]}</FormHelperText>
        </FormControl>
    )
}

export default withStyles(style)(renderTextFieldBlack)
