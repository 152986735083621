import React, { Component } from 'react'
import { connect } from 'react-redux'

const retroactionContainer = (ComposedComponent) => {
    class RetroactionContainer extends Component {
        render() {
            return (
                <ComposedComponent {...this.props} />
            )
        }
    }
    const mapStateToProps = ({
        langs: { indexLang },
        retroaction: { isSending, succeeded },
        user: { values: { firstName } },
        orders: { items, orderUpdated },
        boxSubscription: { pauseSubscriptionId, resumeSubscriptionId, confirmation, modal, title, text, confirmType },
    }, {
        match: { params: { orderId } },
    }) => ({
        indexLang,
        isSending,
        succeeded,
        firstName,
        items,
        order: items.find((item) => item.item.subId === orderId),
        pauseSubscriptionId,
        resumeSubscriptionId,
        confirmation,
        modal,
        title,
        text,
        orderUpdated,
    })
    return connect(mapStateToProps)(RetroactionContainer)
}

export default retroactionContainer
