import React, { Component } from 'react';
import history from '../../containers/routers/history';
import formatPrice from '../../utils/formatPrice';
import slugIt from '../../utils/slugIt';

class OrderCartItem extends Component {
  state = {
      elevation: 1,
  }
  handleMouseEnter = () => this.setState({ elevation: 4 })
  handleMouseLeave = () => this.setState({ elevation: 1 })
  handleNavigation = (e) => {
      e.stopPropagation()
      const { item: { name, productId } } = this.props
      return history.push(`/products/${slugIt(name)}/${productId}`)
  }
  render () {
      const {
          indexLang,
          item: {
              productQty,
              name,
              price,
              total,
          },
      } = this.props
      return (
          <div
              style={{ zDepth: this.state.elevation }}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
          >
              <div style={{ display: 'flex', flexFlow: 'row nowrap' }}>
                  <div style={{
                      display: 'flex',
                      flexFlow: 'row wrap',
                      alignItems: 'flex-start',
                      justifyContent: 'space-between',
                      flex: '1 1 auto',
                  }}>
                      <span style={{ width: 200, fontSize: '1rem', margin: 16 }}>{name}</span>
                      <span style={{ flex: '0 0 auto', fontSize: '1rem', textAlign: 'right', margin: 16 }}>{formatPrice(price, indexLang)}</span>
                      <span style={{ flex: '0 0 auto', fontSize: '1rem', textAlign: 'right', margin: 16 }}>
                          {productQty}
                      </span>
                      <span style={{ flex: '0 0 auto', fontSize: '1rem', textAlign: 'right', margin: 16 }}>{formatPrice(total, indexLang)}</span>
                  </div>
              </div>
          </div>
      )
  }
}

export default OrderCartItem
