import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import WhyGPContainer from '../../containers/whygp/whygpContainer';
import { SEASONALBOX, TIMESPERYEAR, CANADIANCOMPAGNY, FLEXIBLESUBSCRIPTION, PRODUCTVARIETY, 
    MEMORYFOAM, UNIQUEDESIGNS, MORETHANTHREATS, PASSIONNATETEAM, MEMORYFOAMTITLE, UNIQUEDESIGNSTITLE, MORETHANTHREATSTITLE, PASSIONNATETEAMTITLE, B1BQUALITYSHORT, 
} from '../../constant/stringTraduction';
import slider_en from '../../assets/whygp/why_goodypack_en.png'
import slider_fr from '../../assets/whygp/pourquoi_goodypack_fr.png'
import { ReactComponent as PictoCan } from '../../assets/pictos/canada.svg'
import { ReactComponent as PictoQuebec } from '../../assets/pictos/qc.svg'
import picto_saisons from '../../assets/pictos/seasonal.png'
import { ReactComponent as PictoJouetLaisse } from '../../assets/pictos/products.svg'
import { ReactComponent as PictoMain } from '../../assets/pictos/human_pet.svg'
import { ReactComponent as PictoQualiteB1B } from '../../assets/pictos/quality.svg'
import chien_jouet from '../../assets/whygp/chien_jouet.png'
import chat_cloud_pillow from '../../assets/whygp/chat_cloud_pillow.png'
import chiot_laisse from '../../assets/whygp/chiot_laisse.png'
import './whygp.css';
import VideoCorpo from './VideoCorpo';

class WhyGP extends Component {
    constructor() {
        super();
        this.state = {
        }
    }

    render() {
        const { indexLang } = this.props;
        return (
            <div style={{ textAlign: 'center', marginTop: '64px', maxWidth:'100%' }}>
                <img src={indexLang === 1 ? slider_fr : slider_en} style={{ width:'100%', height:'100%' }} alt="Slider page"></img>
                <div className="white-background">
                    <Container style={{ padding:25 }}>
                        <Row>
                            <Col lg='6'>
                                <Row>
                                    <Col lg='3'>
                                        <img src={picto_saisons} alt="Picto saisons"></img>
                                    </Col>
                                    <Col lg='9'style={{ padding:25 }}>
                                        <h3 className="picto-text">{SEASONALBOX[indexLang]}</h3>
                                        <h3 className="picto-text">{TIMESPERYEAR[indexLang]}</h3>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg='6'>
                                <Row>
                                    <Col lg='3' >
                                        {indexLang === 1 ? <PictoQuebec alt="Picto drapeau" /> : <PictoCan alt="Picto drapeau" />}
                                    </Col>
                                    <Col lg='9'style={{ padding:25 }}>
                                        <h3 className="picto-text">{CANADIANCOMPAGNY[indexLang]}</h3>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop:25 }}>
                            <Col lg='6'>
                                <Row>
                                    <Col lg='3' >
                                        <PictoMain alt="Picto main" />
                                    </Col>
                                    <Col lg='9'style={{ padding:25 }}>
                                        <h3 className="picto-text">{FLEXIBLESUBSCRIPTION[indexLang]}</h3>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg='6'>
                                <Row>
                                    <Col lg='3' >
                                        <PictoJouetLaisse alt="Picto jouet_laisse" />
                                    </Col>
                                    <Col lg='9'style={{ padding:25 }}>
                                        <h3 className="picto-text">{PRODUCTVARIETY[indexLang]}</h3>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="background-yellow">
                    <Container>
                        <Row>
                            <div style={{ margin:'auto' }}>
                                <Row className="row-b1b-quality" >   
                                    <PictoQualiteB1B className="quality-img" style={{ padding:0 }} alt="Picto quality"/>
                                    <h3 className="quality-title">{B1BQUALITYSHORT[indexLang]}</h3>
                                </Row>
                            </div>
                        </Row>
                    </Container>
                </div>
                <div className="white-background">
                    <Container style={{ paddingTop:25, paddingBottom:25 }}>
                        
                        <Row style={{ padding:25 }}>
                            <Col lg='4'>
                                <img className="description-image" src={chat_cloud_pillow} alt="chat_coussin"></img>
                            </Col>
                            <Col lg='8' className="description-col">
                                <div className="description-bloc white-background">
                                    <h3 className="description-title text-right-over-992">{MEMORYFOAMTITLE[indexLang]}</h3>
                                    <h3 className="description-text text-right-over-992">{MEMORYFOAM[indexLang]}</h3>
                                </div>
                            </Col>
                        </Row>
                        <hr className="whygp-hr"></hr>
                        <Row style={{ padding:25 }}>
                            <Col lg='4'>
                                <img className="description-image" src={chien_jouet} alt="chien_jouet"></img>
                            </Col>
                            <Col lg='8' className="description-col">
                                <div className="description-bloc white-background">
                                    <h3 className="description-title text-left-over-992">{UNIQUEDESIGNSTITLE[indexLang]}</h3>
                                    <h3 className="description-text text-left-over-992">{UNIQUEDESIGNS[indexLang]}</h3>
                                </div>
                            </Col>
                        </Row>
                        <hr className="whygp-hr"></hr>
                        <Row style={{ padding:25 }}>
                            <Col lg='4'>
                                <img className="description-image" src={chiot_laisse} alt="chiot_laisse"></img>
                            </Col>
                            <Col lg='8' className="description-col">
                                <div className="description-bloc white-background">
                                    <h3 className="description-title text-right-over-992">{MORETHANTHREATSTITLE[indexLang]}</h3>
                                    <h3 className="description-text text-right-over-992">{MORETHANTHREATS[indexLang]}</h3>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </div>
                <div className="background-yellow passionate-team">
                    <Container>
                        <Row>
                            <h3 className="passionate-team-title">{PASSIONNATETEAMTITLE[indexLang]}</h3>
                        </Row>
                    </Container>
                </div>
                <div className="white-background">
                    <Container>
                        <Row style={{ padding:25 }}>
                            <h3 className="description-text" style={{ margin:'auto' }}>{PASSIONNATETEAM[indexLang]}</h3>
                        </Row>
                        <VideoCorpo
                            indexLang={indexLang}
                        />
                    </Container>
                </div>
            </div>
        );
    }

}
export default WhyGPContainer(WhyGP)
