import CircularProgress from '@material-ui/core/CircularProgress';
import Payment from 'payment';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { fetchUpdateCard } from '../../actions/orders';
import { CVC, EXPIRATIONCARTE, NUMEROCARTE, MODIFYCREDITCARD, CARDMODIFIED } from '../../constant/stringTraduction';
import validateCreditCard from '../../utils/validateCreditCard';
import SuccessableButton from '../buttons/SuccessableButton';
import renderTextFieldBlack from '../fields/renderTextFieldBlack';

const OrderModifyCard = ({
    order,
    handleSubmit,
    dispatch,
    submitSucceeded,
    submitting,
    indexLang,
    isFetching,
    pristine,
    invalid,
    errorCard,
    currentCardExp,
    currentCardLast4Digits,
    currentBrand,
    stripePk,
    resetError,
    getCardInfo,
}) => {

    const handleCardFormSubmit = async (values) => {
        await dispatch(fetchUpdateCard({ values, order, indexLang, stripePk }));
        getCardInfo();
    }

    return (
        <Container style={{ marginTop: 45 }} className='white-background'>
            <div>
                <Row>
                    <Col lg='12'><h4>{MODIFYCREDITCARD[indexLang]}</h4></Col>
                </Row>
                <Row>
                    <Col lg='12'>{currentBrand}</Col>
                    <Col lg='12'>{currentCardExp}</Col>
                    <Col lg='12'>**** **** **** {currentCardLast4Digits}</Col>
                </Row>
                <Row style={{ paddingBottom: 25, borderBottom: 'solid black 1px' }}>
                    <div style={{ padding: '0px 16px' }}>
                        <ul className="credit-card-list">
                            <li><i data-brand="visa" className="fa fa-cc-visa"></i></li>
                            <li><i data-brand="mastercard" className="fa fa-cc-mastercard"></i></li>
                        </ul>
                    </div>
                    <br />
                    <div style={{ width: '100%' }}>
                        <form onSubmit={handleSubmit(handleCardFormSubmit)}>
                            <div className="field-container">
                                <Field
                                    name="number"
                                    label={NUMEROCARTE[indexLang]}
                                    className="field"
                                    component={renderTextFieldBlack}
                                    color='#000'
                                    onFocus={(e) => Payment.formatCardNumber(e.target)}
                                    indexLang={indexLang}
                                />
                                <Field
                                    name="exp"
                                    label={EXPIRATIONCARTE[indexLang]}
                                    className="field"
                                    color='#000'
                                    component={renderTextFieldBlack}
                                    onFocus={(e) => Payment.formatCardExpiry(e.target)}
                                    indexLang={indexLang}
                                />
                                <Field
                                    name="cvc"
                                    label={CVC[indexLang]}
                                    className="field"
                                    component={renderTextFieldBlack}
                                    color='#000'
                                    onFocus={(e) => Payment.formatCardCVC(e.target)}
                                    indexLang={indexLang}
                                />
                            </div>
                            <Col lg='12' style={{ width: '100%', textAlign: 'center' }}>
                                {isFetching ?
                                    <CircularProgress size={36} style={{ verticalAlign: 'middle', color: '#000000' }} />
                                    :
                                    <SuccessableButton
                                        disabled={pristine || invalid}
                                        error={errorCard}
                                        label={MODIFYCREDITCARD[indexLang]}
                                        reset={resetError}
                                        submitSucceeded={submitSucceeded}
                                        submitting={submitting}
                                        successLabel={CARDMODIFIED[indexLang]}
                                    />
                                }
                            </Col>
                        </form>
                    </div>
                </Row>
            </div>
        </Container>
    )
}

export default reduxForm({
    form: 'NewCreditCardForm',
    validate: validateCreditCard,
})(withRouter(OrderModifyCard))
