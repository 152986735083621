import React, { Component } from 'react'
import { connect } from 'react-redux'

const returnPolicyContainer = (ComposedComponent) => {
    class ReturnPolicyContainer extends Component {
        render() {
            return (
                <ComposedComponent {...this.props} />
            )
        }
    }
    const mapStateToProps = ({
        langs: { indexLang },
        returnPolicy: { isSending, succeeded },
        boxSubscription: { modal, title, text },
    }) => ({
        indexLang,
        isSending,
        succeeded,
        modal,
        title,
        text,
    })
    return connect(mapStateToProps)(ReturnPolicyContainer)
}

export default returnPolicyContainer
