import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import { FERMER } from '../../constant/stringTraduction';


const AsSeenOnVideoModal = ({ indexLang, Opened, closeVideo, modalId, indexVideo }) => {
    const videos = ['https://www.youtube.com/embed/' + ['uXS6GhXmjAg', 'Jx76yuo0kAM'][indexLang], 'https://www.youtube.com/embed/' + ['uDT92SRNImY', '0fsrgSmRRlc'][indexLang]];
    const videoTitles = [['GoodyPack on Radio-Canada', 'GoodyPack à Radio-Canada'], ['GoodyPack on Salut Bonjour', 'GoodyPack à Salut Bonjour']];

    return (
        <Modal id={modalId} isOpen={Opened} backdrop='static' className='video-modal'>
            <ModalHeader toggle={closeVideo} style={{ background: '#666666', border: 'none', fontSize: '14px!important', textAlign: 'center' }} >{videoTitles[indexVideo][indexLang]}</ModalHeader>
            <ModalBody style={{ background: '#666666' }}>
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe className="embed-responsive-item" src={videos[indexVideo] + '?rel=0'} title={videoTitles[indexVideo][indexLang]} allowFullScreen></iframe>
                </div>
            </ModalBody>
            <ModalFooter style={{ background: '#666666', border: 'none', display: 'flex', justifyContent: 'center' }} >
                <Button className="yellow-button" onClick={closeVideo}>
                    {FERMER[indexLang]}
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default AsSeenOnVideoModal
