export const fetchSendUnsubscribeRetroaction = (values) => {
    return (dispatch) => {
        dispatch({ type: 'IS_SENDING_MESSAGE' });
        return fetch(`/api/retroaction/unsubscribe`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values),
        })
            .then((res) => {
                if (res.ok) {
                    return res.json(); 
                }
                dispatch({ type: 'IS_DONE' });
                throw new Error('Network response was not ok.');
            })
            .then((json) => {
                if (json.error) {
                    console.log(json.error);
                    return Promise.reject(json.error);
                }
                dispatch({ type: 'SUCCEEDED' });
            })
            .catch((error) => console.log(error))
    }
}

export const resetRetroactionForm = () => {
    return { type: 'RESET_FORM' }
}

// Pas utilisé pour l'instant
export const fetchSendAppreciationRetroaction = (values) => {
    return (dispatch) => {
        dispatch({ type: 'IS_SENDING_MESSAGE' });
        return fetch(`/api/retroaction/appreciation`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values),
        })
            .then((res) => {
                if (res.ok) {
                    return res.json(); 
                }
                dispatch({ type: 'IS_DONE' });
                throw new Error('Network response was not ok.');
            })
            .then((json) => {
                if (json.error) {
                    console.log(json.error);
                    return Promise.reject(json.error);
                }
                dispatch({ type: 'SUCCEEDED' });
            })
            .catch((error) => console.log(error))
    }
}

export const fetchAppreciationYesNo = (values) => {
    fetch(`/api/retroaction/thanks`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(values),
    })
        .then((res) => {
            if (res.ok) {
                return res.json(); 
            }
            throw new Error('Network response was not ok.');
        })
        .catch((error) => console.log(error))
}
