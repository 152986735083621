import React from 'react';
import notFoundPageContainer from '../../containers/not-found/NotFoundPageContainer';
import { Container } from 'reactstrap';
import { PAGENOTAVAILABLE, SORRYPAGENOT, CATSTUCK } from '../../constant/stringTraduction';
import chat1 from '../../assets/chat-01.png';
import './notFoundPage.css';

const NotFoundPage = ({
    indexLang,
}) => 
    <div style={{ textAlign: 'center' }}>
        <img className='notFound-picto' src={chat1} alt={CATSTUCK[indexLang]}/>
        <Container style={{ padding: '25px 0 50px 0' }}>
            <h2 className='notFound-title'>{PAGENOTAVAILABLE[indexLang]}</h2>
            <div>{SORRYPAGENOT[indexLang]}</div>
        </Container>
    </div>
      ;

export default notFoundPageContainer(NotFoundPage)
